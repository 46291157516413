<template>
  <div>
    <v-app-bar dark color="primary">
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

      <router-link to="/"
        ><v-img
          class="mx-2"
          src="@/assets/logo.png"
          max-height="40"
          max-width="40"
        ></v-img
      ></router-link>

      <nav class="mx-4" v-if="!$vuetify.breakpoint.mobile">
        <router-link :to="{ name: 'Events' }">Events</router-link>
        <router-link to="/volunteers">Volunteers</router-link>
        <router-link to="/Contacts">Contacts</router-link>
        <router-link to="/reports">Reports</router-link>
      </nav>

      <v-spacer></v-spacer>
      <div>
        <v-text-field
          v-model="searchTerm"
          light
          solo
          hide-details
          append-icon="mdi-magnify"
          placeholder="Search..."
          class="nav-search"
          v-on:keyup.enter="onEnter"
        >
        </v-text-field>
      </div>
      <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-plus-circle</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <router-link :to="{ name: 'EventNew' }">
              <v-list-item-title>Create Event</v-list-item-title>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link :to="{ name: 'VolunteerNew' }">
              <v-list-item-title>Create Volunteer</v-list-item-title>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link :to="{ name: 'VenueNew' }">
              <v-list-item-title>Create Venue</v-list-item-title>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link :to="{ name: 'NonprofitNew' }">
              <v-list-item-title>Create Nonprofit</v-list-item-title>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link :to="{ name: 'ArtistNew' }">
              <v-list-item-title>Create Artist</v-list-item-title>
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link :to="{ name: 'ContactNew' }">
              <v-list-item-title>Create Contact</v-list-item-title>
            </router-link>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group active-class="deep-purple--text text--accent-4">
        
          <v-list-item :to="{ name: 'Home' }" exact>
            <v-list-item-icon>
              <v-icon>mdi-antenna</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'ArtistsIndex' }">
            <v-list-item-icon>
              <v-icon>mdi-music-note</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Artists</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'VenuesIndex' }">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Venues</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'NonprofitsIndex' }">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Nonprofits</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'EmailsIndex' }">
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Emails</v-list-item-title>
          </v-list-item>

          <v-list-item :to="{ name: 'UsersIndex' }">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item>
          
          <!-- Start mobile-only nav items -->
          <v-list-item :to="{ name: 'Events' }" v-if="$vuetify.breakpoint.mobile">
            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Events</v-list-item-title>
          </v-list-item>
          
          <v-list-item :to="{ name: 'VolunteersIndex' }" v-if="$vuetify.breakpoint.mobile">
            <v-list-item-icon>
              <v-icon>mdi-hand-heart</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Volunteers</v-list-item-title>
          </v-list-item>
          
          <v-list-item :to="{ name: 'ContactsIndex' }" v-if="$vuetify.breakpoint.mobile">
            <v-list-item-icon>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contacts</v-list-item-title>
          </v-list-item>
          
          <v-list-item :to="{ name: 'Reports' }" v-if="$vuetify.breakpoint.mobile">
            <v-list-item-icon>
              <v-icon>mdi-chart-box</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item>
          <!-- End mobile-only nav items -->
          
          <v-list-item :to="{ name: 'SettingsIndex' }">
            <v-list-item-icon>
              <v-icon>mdi-settings</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
          
          <v-list-item v-if="$store.state.auth && $store.state.auth.token && $store.state.auth.user" @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
          
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    searchTerm: "",
  }),
  methods: {
    onEnter: function() {
      this.$emit("search", this.searchTerm);
      this.searchTerm = "";
    },
    logout: function() {
      this.$store.dispatch("auth/logout").then(() => {
        this.$router.push({ name: 'Login' });
      });
    }
  },
};
</script>

<style>
nav a {
  color: white !important;
  text-decoration: none;
  margin-right: 2rem;
}
.nav-search.v-input .v-input__slot {
  background: transparent !important;
  box-shadow: none !important;
  border: 0.5px solid #ffffff80;
  border-radius: 3px;
  color: #fff;
}

.nav-search input::placeholder {
  color: #fff !important;
  opacity: 0.5;
}
.nav-search .v-icon.v-icon {
  color: #fff;
}

.router-link-active {
  font-weight: 600 !important;
}

/* Override default black color */
.v-navigation-drawer .theme--light.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #0F7133 !important;
}
</style>
