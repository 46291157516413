const VOLUNTEER_SHIFTS_UPDATED = (state, volunteer_shifts) => {
  state.volunteer_shifts = volunteer_shifts.data;
};

const VOLUNTEER_SHIFT_UPDATED = (state, volunteer_shift) => {
  state.volunteer_shift = volunteer_shift.data;
};

const VOLUNTEER_SHIFT_RESET = (state) => {
  state.volunteer_shift = null;
};

const VOLUNTEER_SHIFTS_DETAILS_UPDATED = (state, volunteer_shifts_details) => {
  state.volunteer_shifts_details = volunteer_shifts_details.data;
};

const VOLUNTEER_SHIFT_DETAILS_UPDATED = (state, shiftDetails) => {
  state.volunteer_shifts_details = [
    ...state.volunteer_shifts_details.filter((obj) => obj.shift_id !== shiftDetails.shift_id),
    shiftDetails
  ]
}

export default {
  VOLUNTEER_SHIFTS_UPDATED,
  VOLUNTEER_SHIFT_UPDATED,
  VOLUNTEER_SHIFT_RESET,
  VOLUNTEER_SHIFTS_DETAILS_UPDATED,
  VOLUNTEER_SHIFT_DETAILS_UPDATED
};
