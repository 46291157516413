import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./plugins/moment";
import store from "./store";
import "./plugins/axios";
Vue.config.productionTip = false;

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount("#app");
});

router.beforeResolve((to, from, next) => {
  if (to.name != "Login") {
    store.dispatch("auth/attempt", localStorage.getItem("token"));
  } else {
    if (store.getters["auth/authenticated"]) {
      router.push({ name: "Home" });
    }
  }
  
  if(to.name === "EventShifts") {
    // Clear existing event & event shifts from store,
    // preventing incorrect event/shift data being shown to user
    store.commit("events/EVENT_RESET_ONLY");
    store.commit("shifts/SHIFTS_RESET");
  }

  store.dispatch("global/updateLoading", true, { root: true });
  store.dispatch("global/updateAlert", {
    visible: false,
    message: null,
    type: null,
  });
  next();
});


Vue.mixin({
  methods: {
    formatVenueName: function(venue) {
      let state = null;
      
      if(venue?.state?.abbreviation) {
        state = venue.state.abbreviation;
      }
      // This usually has abbreviations, but sometimes full state names
      else if(venue?.state_text) {
        state = venue.state_text;
      }
      else if(venue?.state?.name) {
        state = venue.state.name;
      }
      
      if(venue.name && venue.city && state) {
        return `${venue.name} - ${venue.city}, ${state}`;
      }
      else if(venue.name && venue.city) {
        return `${venue.name} - ${venue.city}`;
      }
      else if(venue.name && state) {
        return `${venue.name} - ${state}`;
      }
      
      return venue.name;
    }
  }
});
